// import Header from './Component/Header';
import Footer from './Component/Footer';
import MenuBar from './Component/MenuBar';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';
const VideoLectures = () => {
    return (

        <>

    <Helmet>
        <title> Yugalkunj Canada Hamiltan </title>
          <meta name="description" content="Join our exciting Summer Camp in Hamilton 2024! Fun-filled activities, learning opportunities, and memorable experiences for kids of all ages." />
          <meta name="keywords" content="Summer camp Hamilton 2024, Summer camp 2024, summer camp, Hamilton, 2024, kids activities, outdoor fun, learning, education" />
          <meta name="author" content="Yugalkunj Canada Hamilton" />
        <link rel="canonical" href="https://yugalkunj.org/Canada/Camps/Summer_Camp_Hamilton" />
      </Helmet>
            {/* <Header/> */}

            <MenuBar />
            {/* <!-- Page Banner Section --> */}
            <section className="page-banner">
                <div className="image-layer" ></div>

                <div className="auto-container">
                    <h1>Video Lectures</h1>
                </div>

                <div className="breadcrumb-box">
                    <div className="auto-container">
                        <ul className="bread-crumb clearfix">
                            <li><Link to='/CAD/Home'>Home</Link></li>
                            <li className="active">Video Lectures</li>
                        </ul>
                    </div>
                </div>

            </section>

            {/* <!--Causes Section / Load Number Here to load number of items on load more btn click--> */}
            <section className="causes-section causes-page load-more-section" data-load-number="3">
                <div className="auto-container">

                    <div className="row clearfix">

                        {/* <!--Cause Block--> */}
                        <div className="cause-block col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInUp" data-wow-delay="0ms">
                                <div className="image-box">
                                    <figure className="image">
                                        <iframe
                                            width="400"
                                            height="315"
                                            src="https://www.youtube.com/embed/k3eBni2s7AI"


                                        >
                                        </iframe>
                                    </figure>

                                </div>

                                {/* <!-- <div className="link-box"><a  className="theme-btn btn-style-two"><span className="btn-title">God can only be Known through Grace</span></a></div> --> */}
                            </div>
                        </div>

                        {/* <!--Cause Block--> */}
                        <div className="cause-block col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInUp" data-wow-delay="300ms">
                                <div className="image-box">
                                    <figure className="image">
                                        <iframe width="400"
                                            height="315"
                                            src="https://www.youtube.com/embed/Xd9LHjA_v0o"

                                        >
                                        </iframe>
                                    </figure>

                                </div>

                                {/* <!--     <div className="link-box"><a href="donate.html" className="theme-btn btn-style-two"><span className="btn-title">Donate Now</span></a></div> --> */}
                            </div>
                        </div>

                        {/* <!--Cause Block--> */}
                        <div className="cause-block col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInUp" data-wow-delay="600ms">
                                <div className="image-box">
                                    <figure className="image"><iframe width="400" height="315" src="https://www.youtube.com/embed/K1vT_bk4T2w" ></iframe></figure>

                                </div>

                                {/* <!-- <div className="link-box"><a href="donate.html" className="theme-btn btn-style-two"><span className="btn-title">Donate Now</span></a></div> --> */}
                            </div>
                        </div>

                        {/* <!--Cause Block--> */}
                        <div className="cause-block col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInUp" data-wow-delay="0ms">
                                <div className="image-box">
                                    <figure className="image"><iframe width="400" height="315" src="https://www.youtube.com/embed/h5PWYqQRdBs" ></iframe></figure>

                                </div>

                                {/* <!--  <div className="link-box"><a href="donate.html" className="theme-btn btn-style-two"><span className="btn-title">Donate Now</span></a></div> --> */}
                            </div>
                        </div>

                        {/* <!--Cause Block--> */}
                        <div className="cause-block col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInUp" data-wow-delay="300ms">
                                <div className="image-box">
                                    <figure className="image"><iframe width="400" height="315" src="https://www.youtube.com/embed/fpB7ACc7964" ></iframe></figure>

                                </div>

                                {/* <!-- <div className="link-box"><a href="donate.html" className="theme-btn btn-style-two"><span className="btn-title">Donate Now</span></a></div> --> */}
                            </div>
                        </div>

                        {/* <!--Cause Block--> */}
                        <div className="cause-block col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInUp" data-wow-delay="600ms">
                                <div className="image-box">
                                    <figure className="image"><iframe width="400" height="315" src="https://www.youtube.com/embed/vwFxMifwNTg" ></iframe></figure>

                                </div>
                            </div>


                        </div>
                    </div>

                    {/* <!--Cause Block--> */}
                    {/* <!--  <div className="cause-block load-more-item col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box">
                    <div className="image-box">
                        <figure className="image"><a href="cause-single.html"><img src="https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/resource/cause-image-1.jpg" alt=""></a></figure>
                        <div className="progress-box">
                            <div className="bar">
                                <div className="bar-inner count-bar" data-percent="80%"><div className="count-text">80%</div></div>
                            </div>
                        </div>
                    </div>
                    <div className="lower-content">
                        <h3><a href="cause-single.html">Medical Treatment</a></h3>
                        <div className="cause-title">Medicine</div>
                        <div className="text">Auis nostrud exercitation ullam laboris aliquip exsed consequat duis aut psum dolore magna aliqua minim.</div>
                        <div className="donate-info clearfix">
                            <div className="goal"><i className="fa fa-crosshairs"></i> <span>Goal:</span> <strong>$10,000</strong> </div>
                            <div className="raised"><i className="fa fa-chart-line"></i> <span>Raised:</span> <strong>$6,500</strong> </div>
                        </div>
                    </div>
                    <div className="link-box"><a href="donate.html" className="theme-btn btn-style-two"><span className="btn-title">Donate Now</span></a></div>
                </div>
            </div> --> */}
                    {/* <!--Cause Block-->
                <!--  <div className="cause-block load-more-item col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box">
                    <div className="image-box">
                        <figure className="image"><a href="cause-single.html"><img src="https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/resource/cause-image-2.jpg" alt=""></a></figure>
                        <div className="progress-box">
                            <div className="bar">
                                <div className="bar-inner count-bar" data-percent="50%"><div className="count-text">50%</div></div>
                            </div>
                        </div>
                    </div>
                    <div className="lower-content">
                        <h3><a href="cause-single.html">Feed Hungry Children</a></h3>
                        <div className="cause-title">Food &amp; Water</div>
                        <div className="text">Auis nostrud exercitation ullam laboris aliquip exsed consequat duis aut psum dolore magna aliqua minim.</div>
                        <div className="donate-info clearfix">
                            <div className="goal"><i className="fa fa-crosshairs"></i> <span>Goal:</span> <strong>$10,000</strong> </div>
                            <div className="raised"><i className="fa fa-chart-line"></i> <span>Raised:</span> <strong>$6,500</strong> </div>
                        </div>
                    </div>
                    <div className="link-box"><a href="donate.html" className="theme-btn btn-style-two"><span className="btn-title">Donate Now</span></a></div>
                </div>
            </div> --> */}
                    {/* <!--Cause Block-->
                <!-- <div className="cause-block load-more-item col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box">
                    <div className="image-box">
                        <figure className="image"><a href="cause-single.html"><img src="https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/resource/cause-image-3.jpg" alt=""></a></figure>
                        <div className="progress-box">
                            <div className="bar">
                                <div className="bar-inner count-bar" data-percent="40%"><div className="count-text">40%</div></div>
                            </div>
                        </div>
                    </div>
                    <div className="lower-content">
                        <h3><a href="cause-single.html">Education For People</a></h3>
                        <div className="cause-title">Education</div>
                        <div className="text">Auis nostrud exercitation ullam laboris aliquip exsed consequat duis aut psum dolore magna aliqua minim.</div>
                        <div className="donate-info clearfix">
                            <div className="goal"><i className="fa fa-crosshairs"></i> <span>Goal:</span> <strong>$10,000</strong> </div>
                            <div className="raised"><i className="fa fa-chart-line"></i> <span>Raised:</span> <strong>$6,500</strong> </div>
                        </div>
                    </div>
                    <div className="link-box"><a href="donate.html" className="theme-btn btn-style-two"><span className="btn-title">Donate Now</span></a></div>
                </div>
            </div> --> */}
                    {/* <!--Cause Block--> */}
                    {/* <!--   <div className="cause-block load-more-item col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box">
                    <div className="image-box">
                        <figure className="image"><a href="cause-single.html"><img src="https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/resource/cause-image-4.jpg" alt=""></a></figure>
                        <div className="progress-box">
                            <div className="bar">
                                <div className="bar-inner count-bar" data-percent="80%"><div className="count-text">80%</div></div>
                            </div>
                        </div>
                    </div>
                    <div className="lower-content">
                        <h3><a href="cause-single.html">Good-Bye to Slavery</a></h3>
                        <div className="cause-title">Medicine</div>
                        <div className="text">Auis nostrud exercitation ullam laboris aliquip exsed consequat duis aut psum dolore magna aliqua minim.</div>
                        <div className="donate-info clearfix">
                            <div className="goal"><i className="fa fa-crosshairs"></i> <span>Goal:</span> <strong>$10,000</strong> </div>
                            <div className="raised"><i className="fa fa-chart-line"></i> <span>Raised:</span> <strong>$6,500</strong> </div>
                        </div>
                    </div>
                    <div className="link-box"><a href="donate.html" className="theme-btn btn-style-two"><span className="btn-title">Donate Now</span></a></div>
                </div>
            </div> --> */}
                    {/* <!--Cause Block--> */}
                    {/* <!-- <div className="cause-block load-more-item col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box">
                    <div className="image-box">
                        <figure className="image"><a href="cause-single.html"><img src="https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/resource/cause-image-5.jpg" alt=""></a></figure>
                        <div className="progress-box">
                            <div className="bar">
                                <div className="bar-inner count-bar" data-percent="50%"><div className="count-text">50%</div></div>
                            </div>
                        </div>
                    </div>
                    <div className="lower-content">
                        <h3><a href="cause-single.html">Education For people</a></h3>
                        <div className="cause-title">Food &amp; Water</div>
                        <div className="text">Auis nostrud exercitation ullam laboris aliquip exsed consequat duis aut psum dolore magna aliqua minim.</div>
                        <div className="donate-info clearfix">
                            <div className="goal"><i className="fa fa-crosshairs"></i> <span>Goal:</span> <strong>$10,000</strong> </div>
                            <div className="raised"><i className="fa fa-chart-line"></i> <span>Raised:</span> <strong>$6,500</strong> </div>
                        </div>
                    </div>
                    <div className="link-box"><a href="donate.html" className="theme-btn btn-style-two"><span className="btn-title">Donate Now</span></a></div>
                </div>
            </div> --> */}
                    {/* <!--Cause Block--> */}


                </div>

                {/* <div className="load-more"><a href="#" className="theme-btn load-more-btn">load more</a></div> */}


            </section>




            <Footer />

        </>

    )


}

export default VideoLectures;