import Footer from "./Component/Footer";
// import Header from "./Component/Header";
import MenuBar from "./Component/MenuBar";
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";

const TempleActivities = () => {
    return (
        <>

<Helmet>
        <title> Yugalkunj Canada Hamiltan </title>
          <meta name="description" content="Join our exciting Summer Camp in Hamilton 2024! Fun-filled activities, learning opportunities, and memorable experiences for kids of all ages." />
          <meta name="keywords" content="Summer camp Hamilton 2024, Summer camp 2024, summer camp, Hamilton, 2024, kids activities, outdoor fun, learning, education" />
          <meta name="author" content="Yugalkunj Canada Hamilton" />
        <link rel="canonical" href="https://yugalkunj.org/Canada/Camps/Summer_Camp_Hamilton" />
      </Helmet>
            {/* <Header/> */}

            <MenuBar />
            {/* <!-- Page Banner Section --> */}
            <section className="page-banner">
                <div className="image-layer" ></div>

                <div className="auto-container">
                    <h1>Temple Activities</h1>
                </div>

                <div className="breadcrumb-box">
                    <div className="auto-container">
                        <ul className="bread-crumb clearfix">
                            <li><Link to='/CAD/Home'>Home</Link></li>
                            <li className="active">Temple Activities</li>
                        </ul>
                    </div>
                </div>

            </section>
            {/* <!--End Banner Section --> */}
            {/* <!--FAQs Section--> */}
            <section className="faq-section">
                <div className="auto-container">

                    <div className="row clearfix">

                        <div className="col-md-12">



                            <div className="panel panel-primary">
                                <div className="panel panel-heading">

                                    <h4>Temple Activities</h4>
                                </div>


                                <div className="panel panel-body">
                                    <div className="inner-heading">



                                        <div className="text">


                                        Aarti and satsang form the basis of the daily practice of devotion and regular weekly satsangs are held on Sundays from 10AM to 12PM.  Discourses by Siddheshvari Didi Ji and visiting preachers of Jagadguru Kripalu Parishat, along with easy access to devotional literature, audio CDs and video DVDs help individuals and families advance on the spiritual path.
                                        <br/>
                                        Major Hindu festivals such as Shivratri, Holi, Ram Naumi, Guru Poornima, Janmashtami, Radhashtami and Divali are celebrated here with great enthusiasm and devotion.


                                        </div>



                                        <div className="inner_heading_title orange-border" style={{ marginTop: '10px' }}>
                                            <h4> Weekly Satsangs </h4>

                                            <div className="text">

                                                <p className="lead">
                                                    Weekly satsangs are held every Sunday from 10AM to 12:30PM . The typical satsang follows the schedule shown below
                                                </p>

                                                <ul className="satsang_list">

                                                    <li>Prayer</li>
                                                    <li> Bhajans</li>
                                                    <li>Video discourse by Jagadguru Shri Kripalu Ji Maharaj</li>
                                                    <li>Parallel classes for children</li>
                                                    <li>Aarti</li>
                                                    <li>Lunch Prasad</li>




                                                </ul>
                                            </div>

                                        </div>


                                        <div className="inner_heading_title purple-border">
                                            <h4> Classes </h4>

                                            <div className="text">

                                                <p className="lead">The Following educational and cultural classes are offered:</p>

                                                <ul className="satsang_list">

                                                    <li>Hinduism</li>
                                                    <li>Dholak</li>
                                                    <li>Harmonium</li>
                                                    <li>Dance</li>
                                                    <li>Singing</li>
                                                    <li>Indian languages such as Hindi</li>
                                                    <li>Leadership Workshop</li>


                                                </ul>

                                               <p> <a href="https://ykportal.yugalkunj.org/" className="theme-btn btn-style-one" ><span className="btn-title">To register for any class, please click here.</span></a></p> 

                                               
                                            </div>

                                        </div>
   


                                    </div>



                                </div>



                            </div>

                        </div>


                    </div>


                </div>
            </section>


            <Footer />

        </>


    )


}

export default TempleActivities;