import { Link } from 'react-router-dom'
import React, { useState, useEffect } from 'react';
import footerstyle from '../asstes/css/sass/Footerstyle.module.scss'
const Footer = () => {
    const inlineStyle = {
        textAlign: 'center  !important'
    };

    //   Mobile view  column adjustment 
    const [isMobile, setIsMobile] = useState(false);

    // Detect mobile view on component mount
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
        };

        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);



    return (

        <>

            <footer className={footerstyle.main_footer}>
                <div className={footerstyle.image_layer}></div>

                <div className="auto-container">
                    {/* <!--Widgets Section--> */}
                    <div className={footerstyle.widgets_section}>
                        <div className="row clearfix">

                            {/* <!--Column--> */}
                            <div className={`${footerstyle.column} ${footerstyle.big_column} ${isMobile ? '' : 'offset-2'} col-lg-8 col-md-8 col-sm-8 `}>

                                <div className={`${footerstyle.footer_widget}  ${footerstyle.logo_widget} `}>
                                    <div className={footerstyle.widget_content}>
                                        <div className={`${footerstyle.footer_logo} text-center`}>
                                            <Link to="/">
                                                <img src="https://stssevastorage.blob.core.windows.net/ykwebsite/logo.png" alt="" /></Link>
                                        </div>
                                        <div className={footerstyle.text}>Radha Madhav Society is part of the worldwide mission of Jagadguru Kripalu Parishat (JKP), a non-profit organization founded by Jagadguru Shree Kripalu ji Maharaj to provide a deeper understanding of true Hinduism and bring to light the wonderful resources of this eternal religion and its all-encompassing culture.</div>
                                        {/* <ul className={`${footerstyle.social_links} clearfix text-center`}>
                                            <li><Link to="https://www.facebook.com/yugalkunj/" target="_blank"><span className="fab fa-facebook-f"></span></Link></li>
                                            <li><Link to="https://twitter.com/search?q=yugalkunj" target="_blank"><span className="fab fa-twitter"></span></Link></li>
                                            <li><Link to="https://www.yelp.com/biz/yugal-kunj-duluth-2" target="_blank"><span className="fab fa-yelp"></span></Link></li>
                                            <li><Link to="https://www.instagram.com/yugal_kunj/" target="_blank"><span className="fab fa-instagram"></span></Link></li>
                                            <li><Link to="https://video.search.yahoo.com/search/video;_ylt=AwrC1DGkf51cEi8A_gzQtDMD;_ylu=X3oDMTB0N2Noc21lBGNvbG8DYmYxBHBvcwMxBHZ0aWQDBHNlYwNwaXZz?p=yugal+kunj+duluth+ga&amp;fr2=piv-web&amp;fr=yfp-t-s" target="_blank"><span className="fab fa-yahoo"></span></Link></li>
                                        </ul> */}
                                    </div>
                                </div>
                            </div>
                            {/* <!--Column--> */}

                            <div className='col-md-2'>

                            </div>

                            {/* <!--Column--> */}


                        </div>

                    </div>
                </div>

                {/* <!-- Footer Bottom --> */}
                <div className="footer-bottom text-center" >
                    <div className="auto-container">
                        <div className={footerstyle.inner}>

                            <div className={footerstyle.copyright}>Copyrights (c) 2023 <Link to="/">Yugal kunj </Link> All rights reserved.</div>
                        </div>
                    </div>
                </div>

            </footer >


        </>

    )

}
export default Footer;