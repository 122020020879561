// import Header from './Component/Header';
import WordofWisdomeCard from '../WordofWisdomeCard';
import Footer from './Component/Footer';
import MenuBar from './Component/MenuBar';
import { Helmet } from 'react-helmet';


import { NavLink } from 'react-router-dom'

const WordsOfWisdom = () => {
    return (
        <>
 
      <Helmet>
        <title> Yugalkunj Canada Hamiltan </title>
          <meta name="description" content="Join our exciting Summer Camp in Hamilton 2024! Fun-filled activities, learning opportunities, and memorable experiences for kids of all ages." />
          <meta name="keywords" content="Summer camp Hamilton 2024, Summer camp 2024, summer camp, Hamilton, 2024, kids activities, outdoor fun, learning, education" />
          <meta name="author" content="Yugalkunj Canada Hamilton" />
        <link rel="canonical" href="https://yugalkunj.org/Canada/Camps/Summer_Camp_Hamilton" />
      </Helmet>

            {/* <Header/> */}

            <MenuBar />



            {/* <!-- Page Banner Section --> */}
            {/* <!-- Page Banner Section --> */}
            <section className="page-banner">
                <div className="image-layer" ></div>

                <div className="auto-container">
                    <h1>Words of Wisdom</h1>
                </div>

                <div className="breadcrumb-box">
                    <div className="auto-container">
                        <ul className="bread-crumb clearfix">
                            <li><NavLink to='/CAD/Home'>Home</NavLink></li>
                            <li className="active">
                                Words of Wisdom
                            </li>
                        </ul>
                    </div>
                </div>

            </section>
            {/* <!--End Banner Section --> */}
            {/* <!--End Banner Section --> */}

            {/* <!--Sidebar Page Container--> */}

            {/* <!-- End Sidebar Page Container --> */}


            <WordofWisdomeCard />


            <Footer />

        </>


    )


}

export default WordsOfWisdom;