import Footer from "./Component/Footer"
// import Header from "./Component/Header"
import MenuBar from "./Component/MenuBar"
import { NavLink } from "react-router-dom"
const EventRentals = () => {

    return (
        <>
            <MenuBar />

            {/* <!-- Page Banner Section --> */}
            <section className="page-banner">
                <div className="image-layer"></div>

                <div className="auto-container">
                    <h1>Event-rentals</h1>
                </div>

                <div className="breadcrumb-box">
                    <div className="auto-container">
                        <ul className="bread-crumb clearfix">
                            <li><NavLink to='/USA/Home'>Home</NavLink></li>

                            <li className="active"> Event-rentals</li>
                        </ul>
                    </div>
                </div>

            </section>


            <section className="about-section-two">

                <div className="auto-container">
                    <div className="row clearfix">
                        {/* <!--Text Column--> */}
                        <div className="text-column col-lg-7 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="sec-title">
                                    {/* <!--  <div className="sub-title"><span className="icon"></span> We believe what we achieve</div> --> */}
                                    <h2>Event Rentals</h2>
                                </div>
                                <div className="lower-box">

                                    <div className="text">
                                        Yugal Kunj provides a uniquely beautiful, natural and serene setting for celebrating various events and marking important milestones of your life – weddings, birthdays, anniversaries, baby showers, graduations, reunions, receptions, thread ceremonies and Poojas.  Our rental facilities are also perfectly suited for yoga retreats, workshops, classes, seminars and other events.

                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* <!--Image Column--> */}
                        <div className="image-column col-lg-5 col-md-12 col-sm-12">
                            <div className="inner wow fadeInLeft animated" style={{ visibility: "visible", animationName: "fadeInLeft" }}>
                                <figure className="image-box">
                                    <img src="https://stssevastorage.blob.core.windows.net/ykwebsite/resource/featured-image-5.jpg" alt="" /></figure>
                            </div>
                        </div>
                    </div>


                    <div className="row clearfix">
                        <div className="col-md-12">

                            <div className="lower-box">


                                <p>
                                    We offer air conditioned / heated facilities to make all your events enjoyable and comfortable. We also provide home-style affordable overnight accommodation for guests to enjoy the ashram environment.
                                </p>



                                <p>
                                    The park-like setting of Yugal Kunj provides the perfect location for outdoor weddings in our very own Madhuban.
                                </p>



                                <p>
                                    Also ideal for indoor weddings is Saffron Hall. Various celebrations, and even dining can take place in Saffron Hall and Lotus Hall. We also offer a complete Mandap rental for offsite weddings.
                                </p>



                                <p>
                                    The traditional Sanatan Dharm values we abide by at Yugal Kunj ensure a pure atmosphere for your events, big or small. Not only will you enjoy our heartfelt, devotional hospitality during your event, but you will also earn the merit of helping support the Temple.

                                </p>

                                <label className="alert alert-primary">
                                    For more information:


                                </label>

                                <ul>

                                    <li><a href=""> <i className=" fa fa-phone-alt"></i>  678-920-6669</a></li>
                                    <li><a href=""> <i className=" fa fa-envelope-open"></i> info@yugalkunj.org</a></li>

                                </ul>


                            </div>
                        </div>

                    </div>
                </div>

            </section>











            <Footer />





        </>





    )



}
export default EventRentals